<template>
  <div class="pay">
    <BaseHeaderBar title="Kode pembayaran" :showCustomerService="false" :showBackButton="true" color="#333" bgColor="#FFF" />
    <div class="pay-amount">
      <p class="label">Jumlah pembayaran</p>
      <p class="amount">Rp{{ payInfo.padryAmount }}</p>
    </div>
    <div class="pay-info-list">
      <div>
        <p class="label">Nama Bank</p>
        <p class="value">{{ payInfo.padryChannel }}</p>
      </div>
      <div>
        <p class="label">Metode pembayaran</p>
        <p class="value">{{ payInfo.padryWay }}</p>
      </div>
      <div>
        <p class="label">Masa berlaku</p>
        <p class="value">{{ payInfo.lodrseTime }}</p>
      </div>
      <div v-clipboard:copy="payInfo.padrymentCode" v-clipboard:success="onCopySuccess"  v-clipboard:error="onCopyError">
        <p class="label">Kode pembayaran<img class="copy-btn" src="@/assets/img/copy-btn.jpg" /></p>
        <p class="value">{{ payInfo.padrymentCode }}</p>
      </div>
    </div>
    <van-tabs class="extra-info" type="card" v-model="active" animated swipeable :ellipsis="false" color="#E4EBF3" title-active-color="#333" title-inactive-color="#333">
      <van-tab title="Hal yang perlu diperhatikan">
        <div class="tab-content">
          <p v-for="(item, index) in payInfo.prdrecautions.step" :key="index">{{ item }}</p>
        </div>
      </van-tab>
      <van-tab title="Cara Pembayaran">
        <div class="tab-content">
          <p v-for="(item, index) in payInfo.redrpaymentProcess[0].step" :key="index">{{ item }}</p>
        </div>
      </van-tab>
    </van-tabs>
    <button type="button" class="paid-btn" @click="onPaid">Saya sudah melakukan pembayaran</button>
    <van-dialog v-model="visible" :showConfirmButton="false" close-on-click-overlay>
      <img class="repayment-success-icon" src="@/assets/img/repayment-success-icon.png" />
      <p class="tip">Pembayaran berhasil！</p>
      <button v-if="payInfo.padryCodeid" type="button" class="ok-btn" @click="onConfirm">ok</button>
    </van-dialog>
  </div>
</template>

<script>
import Vue from 'vue'
import { Tab, Tabs, Dialog, Toast } from 'vant'
import dialogVisible from '@/mixins/dialogVisible'
import request from '@/utils/request'
import webviewBack from '@/mixins/webviewBack'
import VueClipboard from 'vue-clipboard2'

Vue.use(Tab)
Vue.use(Tabs)
Vue.use(VueClipboard)

export default {
  name: 'Pay',
  mixins: [dialogVisible, webviewBack],
  components: {
    [Dialog.Component.name]: Dialog.Component
  },
  data () {
    return {
      payInfo: {
        prdrecautions: {},
        redrpaymentProcess: [{}]
      },
      active: 0
    }
  },
  created () {
    this.getPayCode()
  },
  methods: {
    getPayCode () {
      const { repayType, amount, orderId, channelId, payChannel, name, payWay, payWayName } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryAmount: amount,
        padryType: repayType,
        padrymentChannel: channelId,
        padryChannel: payChannel,
        padryChannelName: name,
        padryWay: payWay,
        padryWayName: payWayName
      }
      request.post('crdreatePaymentCode', postData)
        .then(res => {
          this.payInfo = res
        })
    },
    onCopySuccess () {
      Toast('Berhasil disalin')
    },
    onCopyError () {
      Toast('Penyalinan gagal')
    },
    onPaid () {
      const { orderId } = this.$route.query
      const postData = {
        ordrderID: orderId,
        padryCodeid: this.payInfo.padryCodeid
      }
      request.post('redrpaymentStatus', postData)
        .then(res => {
          if (res.padryStatus === 2) {
            this.visible = true
          } else {
            Toast(res.padryMsg)
          }
        })
    },
    onConfirm () {
      this.onCloseDialog()
      this.$router.push('/')
    }
  }
}
</script>

<style scoped lang="less">
.pay-amount {
  margin-top: 85px;
  text-align: center;

  .label {
    font-size: 24px;
    color: #333;
  }

  .amount {
    font-size: 72px;
    font-weight: 600;
    color: #666;
  }
}

.pay-info-list {
  margin: 19px 48px 56px;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 80px;
    line-height: 80px;
    border-bottom: 1px solid #DCDEDF;

    p {
      font-size: 26px;
    }

    .label {
      display: flex;
      align-items: center;
      color: #666;
    }

    .copy-btn {
      width: 30px;
      height: 30px;
      margin-left: 20px;
    }

    .value {
      font-weight: 600;
      color: #33404F;
    }
  }
}

.extra-info {
  border: 16px solid #E4EBF3;
  border-radius: 1px;
  margin-left: 30px;
  margin-right: 30px;

  /deep/ .van-tab__text {
    font-size: 26px;
  }

  /deep/ .van-tabs__nav--card {
    margin: 0;
    border: none;
  }
}

.tab-content {
  height: 415px;
  padding: 39px 42px 0;
  margin-bottom: 53px;
  overflow-y: scroll;

  p {
    line-height: 37px;
    font-size: 26px;
    color: #666;

    &:nth-child(n+2) {
      margin-top: 40px;
    }
  }
}

.paid-btn {
  .submit-btn;
  width: 689px;
  margin-top: 49px;
  margin-bottom: 40px;
}

.repayment-success-icon {
  width: 239px;
  height: 154px;
  margin: 86px auto 0;
}

.tip {
  line-height: 42px;
  margin-top: 23px;
  font-size: 30px;
  color: @theme-color;
  text-align: center;
}

.ok-btn {
  .submit-btn;
  width: 634px;
  margin-top: 73px;
  margin-bottom: 30px;
}
</style>
